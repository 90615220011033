import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTimeoutFn } from 'react-use';
import { useRouter } from 'next/router';

import { Layout } from '@components/layout';
import { ErrorContainer, ErrorNumber } from '@components/common';
import { HOME_ROUTE } from '@utils/constants';

const NotFound = () => {
  const intl = useIntl();
  const router = useRouter();
  const headerTitle = intl.formatMessage({
    id: '404.headerTitle',
    defaultMessage: '404',
  });

  const redirectToHomepage = () => router.push(HOME_ROUTE);
  useTimeoutFn(redirectToHomepage, 2000);

  return (
    <Layout title={headerTitle}>
      <ErrorContainer>
        <h1>
          <ErrorNumber>
            <FormattedMessage id="404.title" defaultMessage="404" />
          </ErrorNumber>
          <FormattedMessage
            id="404.description"
            defaultMessage="The page you're looking for no longer exists, but you'll be redirected to the homepage where you can browse all of Platform."
          />
        </h1>
      </ErrorContainer>
    </Layout>
  );
};

export default NotFound;
